export default [
    {
        path: "/admin/inventory/stock-adjustments",
        name: "stock-adjustment",
        meta: { requiresAuth: true },
        component: () => import("@/pages/inventory/stock-adjustment/index.vue")
    },
    {
        path: "/admin/inventory/stock-adjustments/create",
        name: "stock-adjustment-create",
        meta: { requiresAuth: true },
        component: () => import("@/pages/inventory/stock-adjustment/create.vue")
    }
];
