import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/stock-adjustments", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const store = data => {
    return httpClient
        .post("/api/stock-adjustments", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = data => {
    return httpClient
        .post("/api/stock-adjustments/delete", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const show = id => {
    return httpClient
        .get(`/api/stock-adjustments/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const formModel = (options = {}) => {
    return httpClient
        .get("/api/stock-adjustments/form-view-models", options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, formModel, store, destroy, show };
