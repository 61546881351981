import requestDelivery from "@/api/agency/request-delivery";

import { baseState, baseMutations } from "@/store/mixins";
import { join, split } from "lodash";
import moment from "moment";

export const state = {
    ...baseState,
    status: "",
    service_type_id: [],
    agency_id: [],
    isStockAtJd: undefined,
    formModels: {
        driver: [],
        shop: [],
        vehicleType: [],
        serviceType: [],
        paymentMethod: [],
        deliveryStatus: []
    },
    dateRange: [moment().format("DD-MM-YYYY"), moment().format("DD-MM-YYYY")]
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_STATUS(state, value) {
        state.status = value;
    },
    SET_SERVICE_TYPE(state, value) {
        state.service_type_id = value;
    },
    SET_SERVICE_AGENCY(state, value) {
        state.agency_id = value;
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    },
    SET_IS_STOCK_AT_JD(state, value) {
        state.isStockAtJd = value;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search,
                    date_between:
                        context.state.dateRange.length > 0
                            ? join(context.state.dateRange, ",")
                            : undefined,
                    status: context.state.status,
                    service_type_id: context.state.service_type_id,
                    agency_id: context.state.agency_id,
                    is_stock_at_jd: context.state.isStockAtJd || undefined
                }
            },
            attributes
        );
        return requestDelivery.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    calTransportationFee(context, { delivery_id, distance_km }) {
        return requestDelivery.calTransportationFee(delivery_id, distance_km);
    },
    update(context, { id, data }) {
        return requestDelivery.update(id, data);
    },
    updateCharge(context, { id, data }) {
        return requestDelivery.updateCharge(id, data);
    },
    updateCancelMultiple(context, data) {
      return requestDelivery.updateCancelMultiple(data);
    },
    getDistancePrice(context, { 
        delivery_id,
        service_type_id,
        transaction_date,
        distance_km 
    }) {
        return requestDelivery.getDistancePrice(delivery_id, service_type_id, {
            params: {
                transaction_date, distance_km
            }
        });
    },
    async getFormViewData(context, { params, index }) {
        let response = await requestDelivery.formModel({ params: params });
        context.commit("SET_VIEW_MODELS", {
            data: response.data,
            index: index
        });
        return response;
    }
};
