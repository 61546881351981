import stockAdjustment from "@/api/inventory/stock-adjustment";
import moment from "moment";

import { baseState, baseMutations } from "@/store/mixins";
import { join, isEmpty, split } from "lodash";

export const state = {
    ...baseState,
    formModels: {},
    dateRange: [
        moment()
            .add(-7, "days")
            .format("DD-MM-YYYY"),
        moment().format("DD-MM-YYYY")
    ]
};

export const mutations = {
    ...baseMutations,
    SET_DATE_RANGE(state, date) {
        state.dateRange = date;
    },
    SET_VIEW_MODELS(state, { data, index }) {
        if (!index) {
            state.formModels = data;
        } else {
            split(index, ",").forEach(el => {
                state.formModels[el] = data[el];
            });
        }
    }
};

export const getters = {};

export const actions = {
    fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                branch_id: context.state.branch_id,
                filter: {
                    search: context.state.search,
                    date_between: join(context.state.dateRange, ",")
                }
            },
            attrubutes
        );
        return stockAdjustment.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return stockAdjustment.store(data);
    },
    update(context, { id, data }) {
        return stockAdjustment.update(id, data);
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await stockAdjustment.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    destroy(context, data) {
        return stockAdjustment.destroy(data);
    },
    show(context, id) {
        return stockAdjustment.show(id);
    },
    async getFormViewData(context, { params }) {
        let response = await stockAdjustment.formModel({ params: params });

        return response;
    }
};
